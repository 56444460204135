import {axios} from '@/utils/request'

/**
 * api接口列表
 */
const api = {
    info: '/store.card/info',
    isTrial: '/store.card/isTrial',
    list: '/store.card/list',
    selects: '/store.card/selects',
    add: '/store.card/add',
    edit: '/store.card/edit',
    delete: '/store.card/delete',
    renew: '/store.card/renew'
}

/**
 * 试用模式
 * @param {*} data
 */
export function isTrial(params) {
    return axios({
        url: api.isTrial,
        method: 'get',
        params
    })
}

/**
 * 更新当前管理员信息
 * @param {*} data
 */
export function renew(data) {
    return axios({
        url: api.renew,
        method: 'post',
        data
    })
}

/**
 * 管理员列表
 */
export function getList(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

export function selects(params) {
    return axios({
        url: api.selects,
        method: 'get',
        params
    })
}

/**
 * 新增记录
 * @param {*} data
 */
export function add(data) {
    return axios({
        url: api.add,
        method: 'post',
        data
    })
}

/**
 * 编辑记录
 * @param {*} data
 */
export function edit(data) {
    return axios({
        url: api.edit,
        method: 'post',
        data
    })
}

/**
 * 删除记录
 * @param {*} data
 */
export function deleted(data) {
    return axios({
        url: api.delete,
        method: 'post',
        data: data
    })
}
