<template>
  <a-spin :spinning="loading">
    <div class="table-operator">
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="vr_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        tableLayout="auto"
        :scroll="{x:true,y:500}"
    >
      <div slot="jyd" slot-scope="text, item">
        <b>{{ text == 0 ? '仓库' : '加油站' }}</b>
      </div>

      <div slot="total_amount" slot-scope="text, item">
        <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag v-if="text===0">待审核</a-tag>
        <a-tag color="green" v-if="text===1">审核通过</a-tag>
        <div v-if="text===2" style="color:red">
          <a-tag color="red">审核驳回</a-tag>
          <br>
          {{ item.error_text }}
        </div>
        <a-tag v-if="text===3">已取消</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <template v-if="item.status==0">
          <a v-action:audit @click="handleAudit(item,1)">审核通过</a>
          <a v-action:audit @click="handleAudit(item,2)" style="color:red">审核驳回</a>
        </template>

        <a-popconfirm
            v-action:delete
            title="确认删除当前记录？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(item)"
        >
          <a href="#">删除</a>
        </a-popconfirm>
      </div>
    </s-table>
    <AuditForm ref="AuditForm" @handleSubmit="handleRefresh"/>
  </a-spin>
</template>
<script>
import * as Api from '@/api/vehicle/preDeclaration'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm.vue";
import AuditForm from "./modules/AuditForm.vue";

const columns = [
  [
    // {
    //   title: 'ID',
    //   dataIndex: 'pd_id',
    //   align: 'center',
    //   width: 60,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      width: 100,
      scopedSlots: {customRender: 'status'}
    },
    {
      title: '加油点',
      dataIndex: 'jyd',
      align: 'center',
      scopedSlots: {customRender: 'jyd'}
    },
    {
      title: '加油金额',
      dataIndex: 'jyje',
      align: 'center',
      scopedSlots: {customRender: 'total_amount'}
    },
    {
      title: '预计加油时间',
      dataIndex: 'pd_time',
      align: 'center',
    },
    {
      title: '提交时间',
      dataIndex: 'create_time',
      align: 'center',
    },
    {
      title: 'IP归属地',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: {customRender: 'action'}
    },
  ],
  [
    // {
    //   title: 'ID',
    //   dataIndex: 'pd_id',
    //   align: 'center',
    //   width: 60,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: 60,
      scopedSlots: {customRender: 'status'}
    },
    {
      title: '预计保养时间',
      dataIndex: 'pd_time',
      align: 'center',
    },
    {
      title: '提交时间',
      dataIndex: 'create_time',
      align: 'center',
    },
    {
      title: 'IP归属地',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 180,
      scopedSlots: {customRender: 'action'}
    },
  ],
  [
    // {
    //   title: 'ID',
    //   dataIndex: 'pd_id',
    //   align: 'center',
    //   width: 60,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: 60,
      scopedSlots: {customRender: 'status'}
    },
    {
      title: '预计维修时间',
      dataIndex: 'pd_time',
      align: 'center',
    },
    {
      title: '提交时间',
      dataIndex: 'create_time',
      align: 'center',
    },
    {
      title: 'IP归属地',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 180,
      scopedSlots: {customRender: 'action'}
    },
  ],
  [
    // {
    //   title: 'ID',
    //   dataIndex: 'pd_id',
    //   align: 'center',
    //   width: 60,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: 60,
      scopedSlots: {customRender: 'status'}
    },
    {
      title: '预计时间',
      dataIndex: 'pd_time',
      align: 'center',
    },
    {
      title: '提交时间',
      dataIndex: 'create_time',
      align: 'center',
    },
    {
      title: 'IP归属地',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 180,
      scopedSlots: {customRender: 'action'}
    },
  ],
]
export default {
  props: ['vehicleId', 'pd_type'],
  name: "ShowToolList",
  components: {SearchForm, STable, AuditForm},
  data() {
    return {
      title: '申报记录',
      queryParam: {
        vehicle_id: this.vehicleId,
        pd_type: this.pd_type,
      },
      visible: false,
      loading: false,
      isLoading: false,
      columns: columns[this.pd_type],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 加油记录'
      this.visible = true
      this.vehicle_id = vehicle_id
      this.$nextTick(() => {
        this.handleRefresh(true)
      })

    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDelete(item) {
      Api.deleted({pd_id: item['pd_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleAudit(item, status) {
      if (status == 2) {
        this.$refs.AuditForm.edit(item, status)
        return
      }
      Api.edit({pd_id: item['pd_id'], form: {status}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
