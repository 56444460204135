import {axios} from '@/utils/request'


const pub = "order.orderVehicle";
/**
 * api接口列表
 */
const api = {
    detail: '/' + pub + '/detail',
    info: '/' + pub + '/info',
    list: '/' + pub + '/list',
    listAll: '/' + pub + '/listAll',
    add: '/' + pub + '/add',
    edit: '/' + pub + '/edit',
    delete: '/' + pub + '/delete',
    all: '/' + pub + '/all',
    audit: '/' + pub + '/audit',
}


// 列表记录
export function list(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

export function audit(params) {
    return axios({
        url: api.audit,
        method: 'get',
        params
    })
}

export function listAll(params) {
    return axios({
        url: api.listAll,
        method: 'get',
        params
    })
}

// 订单详情
export function detail(params) {
    return axios({
        url: api.detail,
        method: 'get',
        params
    })
}


/**
 * 新增记录
 * @param {*} data
 */
export function add(data) {
    return axios({
        url: api.add,
        method: 'post',
        data
    })
}

/**
 * 编辑记录
 * @param {*} data
 */
export function edit(data) {
    return axios({
        url: api.edit,
        method: 'post',
        data
    })
}

/**
 * 删除记录
 * @param {*} data
 */
export function deleted(data) {
    return axios({
        url: api.delete,
        method: 'post',
        data: data
    })
}

