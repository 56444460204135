/* eslint-disable */
// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import {VueAxios} from './utils/request'
import ProLayout, {PageHeaderWrapper} from './layouts/ProLayout'
import themePluginConfig from '../config/themePluginConfig'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './global.less'
import VueJsonp from 'vue-jsonp'
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.component('pro-layout', ProLayout)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

Vue.use(VueJsonp)

Vue.use(BaiduMap, {
    ak: 'U8xtHKQdFfChq3oMt45RZiWv9ocohL2a'
})

new Vue({
    router,
    store,
    i18n,
    created: bootstrap,
    render: h => h(App)
}).$mount('#app')
