<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :footer="false"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
<!--    <div class="cpinfo">-->
    <!--      <a-alert v-if="trial==0" message="您有试用资格待领取" type="info"/>-->
    <!--      <a-alert v-if="trial==2" message="您的试用已过期，请选择下方购买" type="error"/>-->
    <!--      <a-alert v-if="trial.start_time_text && trial.end_time_text"-->
    <!--               :message="'试用有效期：'+trial.start_time_text+'~'+ trial.end_time_text" type="success"/>-->
    <!--    </div>-->
    <!--    <div class="card-list-box">-->
    <!--      <div class="list">-->
    <!--        <ul class="active" style="padding: 0;margin-top: 10px">-->
    <!--          <li-->
    <!--              @click="selected(index)"-->
    <!--              v-for="(item,index) in list"-->
    <!--              :class="currIndex===index?'active':''"-->
    <!--          >-->
    <!--            <div class="title">{{ item.package_name }}</div>-->
    <!--            <div class="desc">-->
    <!--              <p v-if="item.package_type==0" class="yh">无车位限制</p>-->
    <!--              <p class="money">-->
    <!--                <i>￥</i>-->
    <!--                <span>{{ item.package_price }}</span>-->
    <!--              </p>-->
    <!--              <p v-if="item.package_type==1" class="old">元/车</p>-->
    <!--              <p v-else class="old">有效期：{{ item.use_time }}天</p>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--        <p class="tip">{{ list[currIndex].package_tips }}</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <a-form :form="form">-->
    <!--      <a-alert message="温馨提示" type="success" :description="description"/>-->
    <!--      <a-form-item style="margin-top: 20px" label="车位数量" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
    <!--        <a-input-number v-model="pay_number" :min="1" :max="10" @change="onChange"/>-->
    <!--      </a-form-item>-->

    <!--      <template v-for="(item,index) in vehicleList">-->
    <!--        <a-form-item :label="'车位'+(index+1)" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
    <!--          <div style="display: flex;align-items: center">-->
    <!--            <a-input v-model="item.vehicle_name" placeholder="请填写当前车位对应车牌号"/>-->
    <!--            <div style="width: 100px"><a style="margin-left: 10px;color: red" @click="delFile(index)">删除</a></div>-->
    <!--          </div>-->
    <!--        </a-form-item>-->
    <!--      </template>-->
    <!--    </a-form>-->
  </a-modal>
</template>
<script>
import {UploadFile, UploadImage} from "@/components";
import * as packageApi from '@/api/package'
import * as cardApi from '@/api/store/card'
import * as Api from '@/api/vehicle'

export default {
  components: {
    UploadImage,
    UploadFile
  },
  data() {
    return {
      title: '会员中心',
      labelCol: {span: 5},
      // 输入框布局属性
      wrapperCol: {span: 14},
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},


      pay_number: 1,
      description: '',
      trial: {},
      vehicleList: [
        {vehicle_name: ''}
      ],
      isAny: true,
      list: [],
      currIndex: 0
    }
  },

  methods: {
    open() {
      this.getTrial()
      this.getcPackageList()
      this.visible = true
    },

    selected(index) {
      this.currIndex = index
    },

    async getcPackageList() {
      const {data: {list}} = await packageApi.list()
      this.list = list
    },
    async getTrial() {
      const {data: {trial}} = await cardApi.isTrial()
      this.trial = 2
      this.description = '您已领取免费试用，当前不限制车位数量。\n试用时间：' + trial.start_time_text + ' - ' + trial.end_time_text
    },
    delFile(index) {
      if (this.vehicleList.length <= 1) {
        this.$message.error('至少保留一项')
        return
      }
      this.isAny = false
      this.vehicleList.splice(index, 1)
      this.pay_number--

      setTimeout(() => {
        this.isAny = true
      }, 500)
    },

    async handleSubmit() {
      const {message} = await Api.addTrialVehicle({form: this.vehicleList})
    },
    handleCancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">

.card-list-box .list {
}

.card-list-box .list ul {
  padding-bottom: 0;
}

.card-list-box .list ul.active {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.card-list-box .list ul li {
  flex-shrink: 0;
  width: 120px;
  margin-right: 7.5px;
}

.card-list-box .list ul li .title {
  width: 100%;
  background: #e9e9e9;
  font-size: 15px;
  text-align: center;
  height: 35px;
  line-height: 35px;
  border-radius: 5px 5px 0 0;
}

.card-list-box .list ul li.active .title {
  background: #0482CB;
  font-weight: bold;
  color: #ffffff;
}

.card-list-box .list ul li .desc {
  border: 1px solid #e9e9e9;
  border-top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 69px;
  padding-bottom: 15px;
  border-radius: 0 0 5px 5px;
}

.card-list-box .list ul li.active .desc {
  background: #f9f9f9;
  border-color: #0482CB;
}

.card-list-box .list ul li .desc .yh {
  display: inline-block;
  font-size: 13px;
  color: #fff;
  background: #9ccbe7;
  height: 18px;
  line-height: 18px;
  padding: 0 10px;
  border-radius: 2.5px;
}

.card-list-box .list ul li .desc .money {
  color: #333;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.card-list-box .list ul li .desc .money i {
  font-size: 13px;
}

.card-list-box .list ul li .desc .money span {
  font-weight: bold;
  font-size: 40rpx;
}

.card-list-box .list ul li .desc .old {
  font-size: 10px;
  color: #999;
}

.card-list-box .list p.tip {
  font-size: 12px;
  color: #999;
  padding-bottom: 15px;
  margin-top: 10px;
  text-indent: 1.2px;
}
</style>
