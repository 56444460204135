<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="保养类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['vm_type', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              保养
            </a-radio-button>
            <a-radio-button :value="1">
              维修
            </a-radio-button>
            <a-radio-button :value="2">
              其他
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="项目名称" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入项目名称"
              v-decorator="['project', {rules: [{required: true, message: '请输入项目名称'}]}]"
          />
        </a-form-item>
        <a-form-item label="金额" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入金额"
              v-decorator="['amount', {rules: [{required: true, message: '请输入金额'}]}]"

          />
        </a-form-item>
        <a-form-item label="金额类型" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-radio-group
              v-decorator="['amount_type', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              自行垫付
            </a-radio-button>
            <a-radio-button :value="1">
              公司已付
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="保养时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
              allowClear
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              v-decorator="['vm_date',{rules: [{required: true, message: '请选择保养时间'}]}]"
          />
        </a-form-item>

        <template v-if="!form.getFieldValue('vm_type') || form.getFieldValue('vm_type')==0">
          <a-form-item label="公里数" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
                suffix="km"
                placeholder="请输入公里数"
                v-decorator="['kilometers', {rules: [{required: true, message: '请输入公里数'}]}]"

            />
          </a-form-item>
          <a-form-item label="仪表公里数拍照" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <MultipleUpload
                :file="record.kilometers_file_list ? record.kilometers_file_list : []"
                v-decorator="['kilometers_file_ids',{rules: [{required: true, message: '请至少上传一个单据'}]}]"
            />
          </a-form-item>
        </template>

        <a-form-item label="单据" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <MultipleUpload
              :file="record.dj_list ? record.dj_list : []"
              v-decorator="['dj',{rules: [{required: true, message: '请至少上传一个单据'}]}]"
          />
        </a-form-item>
        <a-form-item label="实况视频/照片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <MultipleUpload
              accept="image/*,video/*"
              :file="record.skzp_list ? record.skzp_list : []"
              v-decorator="['skzp',{rules: [{required: true, message: '请至少上传一个实况视频/照片'}]}]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/vehicle/maintenanceLog'
import {MultipleUpload} from '@/components'
import _ from "lodash";

export default {
  components: {
    MultipleUpload
  },
  props: ['vehicleId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '添加保养维修记录'
      this.visible = true
      this.record = {}
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑记录'
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },


    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['vm_type', 'project', 'amount', 'amount_type', 'kilometers', 'kilometers_file_ids', 'vm_date', 'dj', 'skzp'])
        setFieldsValue(data)

      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.vehicle_id = this.vehicleId
        // 标记来源后台
        values.source = 1
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({vmId: this.record['vm_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
