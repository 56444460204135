<template>
  <div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in file_list">
        <div style="width: calc(100% - 60px)">{{ item.file_name }}</div>
        <div style="margin-left: 10px;width: 60px;text-align: right">
          <a-tooltip>
            <template slot="title">
              在线预览
            </template>
            <a-icon type="eye" @click="priview(item)"/>
          </a-tooltip>

          <a-tooltip>
            <template slot="title">
              下载文件
            </template>
            <a-icon type="download" style="margin-left: 6px" @click="handleDownload(item)"/>
          </a-tooltip>

          <a-tooltip>
            <template slot="title">
              删除
            </template>
            <a-icon type="delete" style="margin-left: 6px" @click="handleDel(index)"/>
          </a-tooltip>
        </div>
      </div>
      <template v-if="load">
        <a-icon type="loading"/>
      </template>
      <template v-else>
        <a-upload
            v-if="file_list.length<maxFileNumber && !showImg"
            name="iFile"
            :accept="accept"
            :beforeUpload="beforeUpload"
            :customRequest="onUpload"
            :showUploadList="false"
        >
          <div class="list-item">
            +
          </div>
        </a-upload>
      </template>
    </div>
  </div>
</template>
<script>
import {debounce, isVideo} from '@/utils/util'
import * as UploadApi from '@/api/upload'
import PropTypes from "ant-design-vue/lib/_util/vue-types";
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'MultipleUpload',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    file: PropTypes.array.def([]),
    accept: PropTypes.string.def('image/*'),
    maxFileNumber: PropTypes.integer.def(5),
    showImg: PropTypes.bool.def(false),
  },
  watch: {
    file: {
      immediate: true,
      handler(val) {
        const {file_list, allowProps} = this
        if (val.length && !file_list.length && allowProps) {
          this.file_list = cloneDeep(val)
          this.onChange()
        }
      }
    }
  },
  data() {
    return {
      uploadUrl: UploadApi.annex,
      // 文件列表
      file_list: [],
      // 禁止传参 (防止selectedItems为空时defaultList重新赋值)
      allowProps: true,
      load: false
    }
  },
  methods: {
    isVideo,
    // 事件: 上传文件之前
    beforeUpload(file) {
      const that = this
      return new Promise((resolve, reject) => {
        const showErrorMsg = debounce(this.$message.error, 20)
        return resolve(true)
      })
    },
    // 事件: 自定义上传
    onUpload(info) {
      this.load = true
      const formData = new FormData()
      formData.append('iFile', info.file)
      formData.append('groupId', 0)
      // 开始上传
      this.uploadUrl(formData)
          .then(({data: {fileInfo}}) => {
            this.file_list.push(fileInfo)
            this.onChange()
          }).finally(() => {
        this.load = false
      })
    },
    onChange() {
      if (this.file_list.length <= 0) {
        return this.$emit('change', '')
      }

      var fileIds = []
      this.file_list.forEach(item => fileIds.push(item.file_id))
      return this.$emit('change', fileIds.join(','))
    },

    // 删除图片
    handleDel(index) {
      this.file_list.splice(index, 1)
      if (this.file_list.length === 0) {
        this.allowProps = false
      }
      this.onChange()
    },
    priview(item) {
      // if (['jpg', 'png', 'gif', 'jpeg', 'pdf'].includes(item.file_ext)) {
      //   window.open(item.external_url)
      // } else {
      window.open('https://www.pfile.com.cn/api/profile/onlinePreview?url=' + item.external_url)
      // }
    },
    handleDownload(item) {
      const link = document.createElement('a');
      link.href = item.external_url;
      link.download = item.file_name;
      link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
      link.click();
    }
  }
}
</script>

<style scoped lang='less'>
.list {

  .list-item {
    padding: 6px;
    border: 1px #d9d9d9 solid;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0
    }
  }
}

.image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px dashed #e8e8e8;
  color: #dad9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px dashed #40a9ff;
    color: #40a9ff;
  }

  .icon-plus {
    font-size: 32px;
  }
}
</style>
