<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <template v-for="item in record.spotcheck_ic_list">
          <a-divider orientation="left">
            {{ item.category_name }}
          </a-divider>
          <a-form-item
              :label="i2.project_name"
              v-for="i2 in item.inspect_list"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
          >
            <a-radio-group v-model="i2.check">
              <a-radio :value="0">
                完好
              </a-radio>
              <a-radio :value="1">
                故障
              </a-radio>
            </a-radio-group>
            <MultipleUpload
                v-if="i2.check===1"
                v-model="i2.file_ids"
            />
          </a-form-item>
        </template>
        <a-divider orientation="left">
          罚款
        </a-divider>
        <a-form-item label="是否罚款" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['is_penalty', {initialValue:0,rules: [{required: true, message: '请输入检查编号'}]}]"
          >
            <a-radio :value="0">
              否
            </a-radio>
            <a-radio :value="1">
              是
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
            v-if="form.getFieldValue('is_penalty')===1"
            label="罚款金额"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <a-input-number
              style="width: 100%;"
              placeholder="请输入罚款金额"
              v-decorator="['penalty_amount', {rules: [{required: true, message: '请输入罚款金额'}]}]"
          />
        </a-form-item>

        <a-form-item
            v-if="form.getFieldValue('is_penalty')===1"
            label="罚款事由"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <a-textarea
              :maxLength="200"
              v-decorator="['penalty_text', {rules: [{required: true, message: '请输入罚款事由'}]}]"
              placeholder="请输入罚款事由"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/inspect/inspectVehicle'
import _ from "lodash";
import {SelectInspectProjectTree} from '@/components'
import MultipleUpload from "@/components/MultipleUpload/MultipleUpload.vue";
import {isObject} from "@/utils/util";

export default {
  props: {
    inspect_type: {
      type: Number,
      default: 0
    }
  },
  components: {
    MultipleUpload,
    SelectInspectProjectTree
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

    }
  },
  created() {
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    /**
     * 编辑对话框
     */
    open(record) {
      console.log(record)

      this.mode = "edit"
      this.title = '检查'
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        this.record.project = this.record.project_array
        const data = _.pick(this.record, ['days', 'status'])
        setFieldsValue(data)

        var list = []
        this.project_list = this.record.project_list
        this.record.project_list.forEach((item, index) => {
          list['project.' + index + '.project_name'] = item.project_name
        })
        this.$nextTick(() => {
          const data2 = _.pick(list, Object.keys(list))
          setFieldsValue(data2)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      values.ic_list = this.record.spotcheck_ic_list
      values.is_fault = 0
      for (var item in this.record.spotcheck_ic_list) {
        const ic = this.record.spotcheck_ic_list[item]
        if (isObject(ic)) {
          for (var item2 in ic.inspect_list) {
            const il = ic.inspect_list[item2]
            if (isObject(il)) {
              if (il.check == 1) {
                values.is_fault = 1
                break
              }
            }
          }

          if (values.is_fault == 1) {
            break
          }
        }
      }

      this.confirmLoading = true
      var sendApi = null
      sendApi = Api.edit({iv_id: this.record['iv_id'], form: values})
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
