<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    地址搜索：
    <a-select
        show-search
        :value="addressIndex"
        placeholder="请输入地点关键词"
        style="width: 300px"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearch"
        @change="handleChange"
    >
      <a-select-option v-for="(item,index) in searchData" :key="index">
        {{ item.title }}
      </a-select-option>
    </a-select>

    <div v-if="!isEmpty(address)" style="border: 1px solid #e8e8e8;padding: 15px;margin-top: 15px">
      <div style="font-weight: bold;font-size: 16px">当前选定地址：</div>
      <div style="">名称：{{ address.title }}</div>
      <div style="">所在地区：{{ address.province }}{{ address.city }}</div>
      <div style="">详细地址：{{ address.address }}</div>
      <div style="text-align: center;margin-top: 10px">
        <a-button @click="handleSubmit" type="primary">确定选择该位置</a-button>
      </div>
    </div>

    <div id="map" style="width: 100%; height: 500px;margin-top: 15px"></div>
  </a-modal>
</template>
<script>
import {isEmpty} from "@/utils/util";
import store from "@/store";

const publicConfig = store.getters.publicConfig
export default {
  name: 'MultipleUpload',

  props: {
    lat: {
      type: String,
      default: ''
    },
    lng: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      // 对话框标题
      title: '地图定位',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,

      keyword: '',

      address: {},
      addressIndex: undefined,
      searchData: []

    }
  },
  methods: {
    isEmpty,
    async getAddress(latLng) {
      this.confirmLoading = true
      const {
        result: {
          address_component,
          formatted_addresses,
          location
        }
      } = await this.$jsonp("https://apis.map.qq.com/ws/geocoder/v1/", {
        location: latLng,
        key: publicConfig.map_key,
        output: 'jsonp',
      })

      this.address.title = formatted_addresses.recommend
      this.address.address = formatted_addresses.standard_address
      this.address.province = address_component.province
      this.address.city = address_component.city
      this.address.location = location
      this.confirmLoading = false
      this.initMap()
    },

    async getIp(record) {
      if (!isEmpty(record)) {
        this.getAddress(record.lat + ',' + record.lng)
        return
      }


      this.confirmLoading = true
      const {result} = await this.$jsonp("https://apis.map.qq.com/ws/location/v1/ip", {
        key: publicConfig.map_key,
        output: 'jsonp',
      })
      this.confirmLoading = false
      this.getAddress(result.location.lat + ',' + result.location.lng)
    },

    async handleSearch(value) {
      const {data} = await this.$jsonp("https://apis.map.qq.com/ws/place/v1/suggestion", {
        keyword: value,
        key: publicConfig.map_key,
        output: 'jsonp',
      })
      this.searchData = data
    },

    async handleChange(value) {
      this.addressIndex = value
      this.address = this.searchData[value]
      this.initMap()
    },

    // 初始化地图组件
    initMap() {
      this.confirmLoading = true
      let that = this;
      const {location: {lat, lng}} = this.address
      this.map = new qq.maps.Map(document.getElementById("map"), {
        center: new qq.maps.LatLng(lat, lng), // 设置初始中心点位置
        zoom: 20, // 地图缩放
      });
      const markerIcon = new qq.maps.MarkerImage('https://zcjh.iyexi.com/assets/position2.png',
          null, // 尺寸
          null, // 偏移量
          null, // 原点
          new qq.maps.Size(35, 35)
      );
      const marker = new qq.maps.Marker({
        position: that.map.getCenter(),
        map: that.map,
        icon: markerIcon
      });
      qq.maps.event.addListener(that.map, 'click', ({latLng}) => {
        marker.setPosition(latLng);
        that.getAddress(latLng.lat + ',' + latLng.lng)
      });
      this.confirmLoading = false
    },

    open(record) {
      this.visible = true
      this.$nextTick(() => {
        this.getIp(record)
      })
    },
    cancel() {
      this.visible = false
    },

    handleSubmit() {
      if (isEmpty(this.address)) {
        this.$message.error('请选择一个位置！')
        return
      }
      this.$emit('handleSuccess', this.address)
      this.cancel()
    }
  }
}
</script>
<style scoped lang="less">

</style>
