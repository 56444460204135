<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="配套工具" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='details'">
            <a>{{ record.tools.join(',') }}</a>
          </template>
          <template v-else>
            <a-button type="primary" size="small" @click="handleAdd('tools')">增加一项</a-button>
            <div class="table">
              <div class="tr">
                <div class="td" style="width: 50%">工具名称</div>
                <div class="td" style="width: 50%">操作</div>
              </div>

              <div class="tr" v-for="(item,index) in sform.tools">
                <div class="td" style="width: 50%">
                  <a-form-item>
                    <a-input
                        placeholder="请输入工具名称"
                        v-decorator="['tools.'+index, {rules: [{required: true, message: '请输入工具名称'}]}]"
                    />
                  </a-form-item>
                </div>
                <div class="td" style="width: 50%">
                  <a style="color: red" @click="handleDel('tools',index)">删除</a>
                </div>
              </div>
            </div>
          </template>
        </a-form-item>
        <a-form-item label="配套设备" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='details'">
            <a>{{ record.devices.join(',') }}</a>
          </template>
          <template v-else>
            <a-button type="primary" size="small" @click="handleAdd('devices')">增加一项</a-button>
            <div class="table">
              <div class="tr">
                <div class="td" style="width: 50%">设备名称</div>
                <div class="td" style="width: 50%">操作</div>
              </div>

              <div class="tr" v-for="(item,index) in sform.devices">
                <div class="td" style="width: 50%">
                  <a-form-item>

                    <a-input
                        placeholder="请输入设备名称"
                        v-decorator="['devices.'+index, {rules: [{required: true, message: '请输入设备名称'}]}]"
                    />
                  </a-form-item>
                </div>
                <div class="td" style="width: 50%">
                  <a style="color: red" @click="handleDel('devices',index)">删除</a>
                </div>
              </div>
            </div>
          </template>
        </a-form-item>
        <a-form-item label="配套证件" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='details'">
            <a>{{ record.cards.join(',') }}</a>
          </template>
          <template v-else>
            <a-button type="primary" size="small" @click="handleAdd('cards')">增加一项</a-button>
            <div class="table">
              <div class="tr">
                <div class="td" style="width: 50%">证件名称</div>
                <div class="td" style="width: 50%">操作</div>
              </div>

              <div class="tr" v-for="(item,index) in sform.cards">
                <div class="td" style="width: 50%">
                  <a-form-item>
                    <a-input
                        placeholder="请输入证件名称"
                        v-decorator="['cards.'+index, {rules: [{required: true, message: '请输入证件名称'}]}]"
                    />
                  </a-form-item>
                </div>
                <div class="td" style="width: 50%">
                  <a style="color: red" @click="handleDel('cards',index)">删除</a>
                </div>
              </div>
            </div>
          </template>
        </a-form-item>
        <!--        <a-form-item label="交车人" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-select-->
        <!--              allowClear-->
        <!--              show-search-->
        <!--              :filter-option="filterOption"-->
        <!--              placeholder="请选择交车人"-->
        <!--              v-decorator="['jcr', {rules: [{required: true, message: '请选择起始地点'}]}]"-->
        <!--          >-->
        <!--            <a-select-option v-for="item in sform.cards" :value="item.address_id">-->
        <!--              {{ item.address_name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->
        <a-form-item label="驾驶员" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='details'">
            <a>{{ record.driver ? record.driver.personnel_name : '' }}</a>
          </template>
          <template v-else>
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['driver_id', {rules: [{required: true, message: '请选择司机'}]}]"
                placeholder="请选择司机"
            >
              <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                {{ item.personnel_name }}
              </a-select-option>
            </a-select>
          </template>
        </a-form-item>
        <a-form-item label="押运员" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='details'">
            <a>{{ record.supercargo ? record.supercargo.personnel_name : '' }}</a>
          </template>
          <template v-else>
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['supercargo_id', {rules: [{required: true, message: '请选择押运员'}]}]"
                placeholder="请选择押运员"
            >
              <a-select-option v-for="item in supercargo_list" :value="item.personnel_id">
                {{ item.personnel_name }}
              </a-select-option>
            </a-select>
          </template>
        </a-form-item>

        <template v-if="mode=='details'">
          <a-form-item label="接车照片" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <MultipleUpload
                :showImg="true"
                :file="record.handover_file_list ? record.handover_file_list : []"
            />
          </a-form-item>

          <a-form-item label="交车人" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a>{{ record.storeuser ? record.storeuser.real_name : '' }}</a>
          </a-form-item>
          <a-form-item label="IP归属地" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a>{{ record.address }}</a>
          </a-form-item>
          <!--          <a-form-item label="提交时间" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
          <!--            <a>{{ record.handover_time }}</a>-->
          <!--          </a-form-item>-->
          <a-form-item label="提交时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a>{{ record.create_time }}</a>
          </a-form-item>


        </template>


        <!--        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-radio-group-->
        <!--              v-decorator="['tire_type', {initialValue:0,rules: [{required: true}]}]"-->
        <!--              button-style="solid"-->
        <!--          >-->
        <!--            <a-radio-button :value="0">-->
        <!--              补/调胎-->
        <!--            </a-radio-button>-->
        <!--            <a-radio-button :value="1">-->
        <!--              新胎更换-->
        <!--            </a-radio-button>-->
        <!--          </a-radio-group>-->
        <!--        </a-form-item>-->

        <!--        <template v-if="form.getFieldValue('tire_type')==1">-->
        <!--          <a-form-item label="更换位置" :label-col="labelCol" :wrapper-col="wrapperCol">-->
        <!--            <a-input-->
        <!--                allowClear-->
        <!--                placeholder="请输入更换位置"-->
        <!--                v-decorator="['position', {rules: [{required: true, message: '请输入项更换位置'}]}]"-->

        <!--            />-->
        <!--          </a-form-item>-->
        <!--          <a-form-item label="公里数" :label-col="labelCol" :wrapper-col="wrapperCol">-->
        <!--            <a-input-->
        <!--                suffix="km"-->
        <!--                placeholder="请输入公里数"-->
        <!--                v-decorator="['kilometers', {rules: [{required: true, message: '请输入公里数'}]}]"-->
        <!--            />-->
        <!--          </a-form-item>-->
        <!--          <a-form-item label="仪表公里数拍照" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--            <MultipleUpload-->
        <!--                :file="record.kilometers_file_ids ? record.kilometers_file_ids : []"-->
        <!--                v-decorator="['kilometers_file_ids',{rules: [{required: true, message: '请至少上传一个仪表公里数拍照'}]}]"-->
        <!--            />-->
        <!--          </a-form-item>-->
        <!--        </template>-->


        <!--        <a-form-item label="单据" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <MultipleUpload-->
        <!--              :file="record.document_file_ids ? record.document_file_ids : []"-->
        <!--              v-decorator="['document_file_ids',{rules: [{required: true, message: '请至少上传一个单据'}]}]"-->
        <!--          />-->
        <!--        </a-form-item>-->

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/vehicle/vehicleHandover'
import * as personnelApi from '@/api/personnel'

import {MultipleUpload} from '@/components'
import _ from "lodash";

export default {
  components: {
    MultipleUpload
  },
  props: ['vehicleId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      sform: {
        tools: [{}],
        devices: [{}],
        cards: [{}],
      },

      // 司机选择列表
      driver_list: [],
      // 押运员选择列表
      supercargo_list: [],

    }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取人员信息
    getPersonnelAll() {
      personnelApi.all().then(({data: {driver_list, supercargo_list}}) => {
        this.driver_list = driver_list
        this.supercargo_list = supercargo_list
      })
    },
    handleAdd(key) {
      this.sform[key].push({})
    },
    handleDel(key, index) {
      if (this.sform[key].length <= 1) {
        this.$message.error('至少保留一项')
        return
      }
      this.sform[key].splice(index, 1)
    },
    /**
     * 显示对话框
     */
    add() {
      this.getPersonnelAll()
      this.mode = "add"
      this.title = '添加接车记录'
      this.visible = true
      this.record = {}
    },
    edit(record) {
      this.getPersonnelAll()

      this.mode = "edit"
      this.title = '编辑轮胎记录'
      this.visible = true
      this.record = record
      this.setFieldsValue()

    },
    details(record) {
      this.getPersonnelAll()
      this.mode = "details"
      this.title = '查看详情'
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },


    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['driver_id', 'supercargo_id'])
        setFieldsValue(data)

        var list = []
        this.record.tools.forEach((item, index) => {
          list['tools.' + index] = item
        })
        this.record.devices.forEach((item, index) => {
          list['devices.' + index] = item
        })
        this.record.cards.forEach((item, index) => {
          list['cards.' + index] = item
        })

        this.sform = {
          tools: this.record.tools,
          devices: this.record.devices,
          cards: this.record.cards,
        }
        this.$nextTick(() => {
          const data2 = _.pick(list, Object.keys(list))
          setFieldsValue(data2)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.vehicle_id = this.vehicleId
        // 标记来源后台
        values.source = 1
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({vh_id: this.record['vh_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        //border: none;
        //outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
