<template>
  <a-modal
      :title="title"
      :width="1370"
      :visible="visible"
      :confirmLoading="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="false">
      <a-tabs :default-active-key="0" @change="callback">
        <a-tab-pane :key="0" :tab="'加油审核('+(data.refuelLog||0)+')'"/>
        <a-tab-pane :key="1" :tab="'保养维修审核('+(data.maintenanceLog||0)+')'"/>
        <a-tab-pane :key="2" :tab="'轮胎审核('+(data.tireLog||0)+')'"/>
        <a-tab-pane :key="3" :tab="'交车审核('+(data.handoverLog||0)+')'"/>
        <a-tab-pane :key="4" :tab="'安全审核('+(data.inspectLog||0)+')'"/>
        <a-tab-pane :key="5" :tab="'订单审核('+(data.orderVehicleLog||0)+')'"/>
        <a-tab-pane :key="6" :tab="'仓库审核('+(data.approveLog||0)+')'"/>
        <a-tab-pane :key="7" :tab="'请假审核('+(data.askleaveLog||0)+')'"/>
      </a-tabs>
      <template v-if="tabKey===0">
        <refuel-log/>
      </template>
      <template v-if="tabKey===1">
        <maintenance-log/>
      </template>
      <template v-if="tabKey===2">
        <tireLog/>
      </template>
      <template v-if="tabKey===3">
        <handover-log/>
      </template>
      <template v-if="tabKey===4">
        <inspect-log/>
      </template>
      <template v-if="tabKey===5">
        <order-vehicle-log/>
      </template>
      <template v-if="tabKey===6">
        <approve-log/>
      </template>
      <template v-if="tabKey===7">
        <askleave-log/>
      </template>
    </a-spin>
  </a-modal>
</template>
<script>
import refuelLog from './refuelLog/Index'
import maintenanceLog from './maintenanceLog/Index'
import tireLog from './tireLog/Index'
import handoverLog from './handoverLog/Index'
import inspectLog from './inspectLog/Index'
import orderVehicleLog from './orderVehicleLog/Index'
import approveLog from './approveLog/Index'
import askleaveLog from './askleaveLog/Index'
import {pendingData} from "@/api/store";

export default {
  name: "PendingModel",
  components: {
    refuelLog,
    maintenanceLog,
    tireLog,
    handoverLog,
    inspectLog,
    orderVehicleLog,
    approveLog,
    askleaveLog
  },
  data() {
    return {
      tabKey: 0,
      title: '待处理事项',
      visible: false,
      data: {}
    }
  },
  methods: {
    callback(e) {
      this.tabKey = e
    },
    open() {
      this.getPendingData()
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    async getPendingData() {
      const {data} = await pendingData()
      this.data = data
    }
  }
}
</script>
<style scoped lang="less">

</style>
