<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <div style="height: 600px;overflow-y: scroll">
        <div class="info" v-for="item in orderVehicleList">
          <div>
            <a-row>
              <a-col :span="6">
                <div>车号： <a>{{ item.vehicle_name }}</a></div>
                <div>状态：
                  <a-tag v-if="item.status==0">待提交审核</a-tag>
                  <a-tag color="orange" v-if="item.status==1">待审核</a-tag>
                  <a-tag color="blue" v-if="item.status==2">审核通过/已完成</a-tag>
                  <a-tag color="red" v-if="item.status==3">审核驳回</a-tag>
                  <a-tag v-if="item.status==4">未收到</a-tag>
                  <a-tag color="blue" v-if="item.status==5">已收到</a-tag>
                </div>
                <div v-if="item.status==3">驳回原因：<a style="color: red">{{ item.reject_text }}</a></div>
              </a-col>
              <a-col :span="6">
                <div>司机： <a>{{ item.driver ? item.driver.personnel_name : '' }}</a></div>
                <div>完成时间：<a>{{ item.complete_time }}</a></div>
              </a-col>
              <a-col :span="6">
                <div>押运员： <a>{{ item.supercargo ? item.supercargo.personnel_name : '' }}</a></div>
              </a-col>
              <a-col :span="6" style="text-align: right">
                <a @click="item.show=!item.show">{{ item.show ? '收起' : '查看' }}跟单信息 <span v-if="item.status==1">/ 审核</span></a>
                <!--                <a v-if="!show"-->
                <!--                   style="margin-left: 10px" @click="handleEdit(item)">编辑</a>-->
                <a-popconfirm
                    v-if="!show"
                    v-action:delete
                    title="确认删除当前记录？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleDelete(item)"
                >
                  <a style="color: red;margin-left: 10px">删除</a>
                </a-popconfirm>


              </a-col>
              <div v-if="item.show">
                <a-col :span="24">
                  <div style="border-top :1px solid #e8e8e8;margin: 10px 0 "></div>
                </a-col>
                <a-col :span="12">
                  <div class="info-item" style="display: flex">
                    <div>装货数量：</div>
                    <div>
                      <a>{{ item.zhsl }} 吨</a>
                    </div>
                  </div>
                  <div class="info-item" style="display: flex">
                    <div>卸货数量：</div>
                    <div>
                      <a>{{ item.xhsl }} 吨</a>
                    </div>
                  </div>
                  <div class="info-item">送货单：
                    <a target="_blank" v-for="img in item.shd" :href="img">
                      <div class="image" :style="'background-image:url('+img+')' "></div>
                    </a>
                  </div>
                  <div class="info-item">费用收据：
                    <a target="_blank" v-for="img in item.fysj" :href="img">
                      <div class="image" :style="'background-image:url('+img+')' "></div>
                    </a>
                  </div>
                </a-col>
                <a-col :span="12">

                  <div class="info-item">装货磅单：
                    <a target="_blank" v-for="img in item.zhbd" :href="img">
                      <div class="image" :style="'background-image:url('+img+')' "></div>
                    </a>
                  </div>
                  <div class="info-item">卸货榜单：
                    <a target="_blank" v-for="img in item.xhbd" :href="img">
                      <div class="image" :style="'background-image:url('+img+')' "></div>
                    </a>
                  </div>
                  <div class="info-item" style="display: flex">
                    <div>费用项目：</div>
                    <div>
                      <div v-for="(co,index) in item.cost_list">{{ index + 1 }}.{{ co.cost_name }}（<span
                          class="price">￥</span>{{ co.cost_price }}）
                      </div>
                    </div>
                  </div>


                  <div class="info-item" style="display: flex">
                    <div>特殊情况备注：</div>
                    <div>
                      <div>{{ item.tsqkbz }}</div>
                      <template v-for="img in item.tsqkbz_file_list">
                        <a
                            v-if="isVideo(img.file_ext)"
                            target="_blank"
                            :href="img.external_url"
                        >
                          <div class="image" :style="'background-image:url('+img.preview_url+')' "></div>
                        </a>
                        <a
                            v-else
                            target="_blank"
                            :href="img.external_url"
                        >
                          <div class="image" :style="'background-image:url('+img.external_url+')' "></div>
                        </a>
                      </template>


                    </div>
                  </div>

                </a-col>

                <a-col :span="24">
                  <div v-if="item.status==1" style="text-align: center">
                    <a-textarea
                        placeholder="审核备注 / 驳回理由"
                        style="margin: 8px 0"
                        v-model="item.reject_text"
                    />
                    <a-button style="color: #04be02" @click="handleAudit(item.ov_id,2,item.reject_text)">审核通过
                    </a-button>
                    <a-button style="color: red;margin-left: 10px" @click="handleAudit(item.ov_id,3,item.reject_text)">
                      审核驳回
                    </a-button>
                  </div>
                </a-col>

              </div>
            </a-row>

          </div>
        </div>
      </div>
    </a-spin>
    <!--添加/编辑model-->
    <SaveOrderVehicle
        :orderId="order_id"
        ref="SaveForm"
        @handleSubmit="getData"
    />
  </div>
</template>
<script>
import * as Api from '@/api/order/vehicle'
import SaveOrderVehicle from "@/views/order/model/orderVehicle/Save.vue";
import {isVideo} from '@/utils/util'

export default {
  name: 'Index',
  components: {
    SaveOrderVehicle
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 4
      },
      // 输入框布局属性
      wrapperCol: {
        span: 18
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      // 订单id
      order_id: '',
      // 订单车辆跟单信息
      orderVehicleList: [],

      show: false,
      queryParams: {
        status: 1,
      },

      reject_text: ''
    }

  },
  created() {
    this.$nextTick(() => {
      this.getData()
    })
  },

  methods: {
    isVideo,
    async handleAudit(ov_id, status, reject_text) {
      const {data} = await Api.audit({ov_id, status, reject_text})
      this.getData()
    },
    /**
     * 显示对话框
     */
    add(order_id, show = false) {
      this.show = show
      this.order_id = order_id
      this.mode = "add"
      this.title = '已调度车辆'
      this.visible = true


    },

    async getData() {
      this.confirmLoading = true
      this.queryParams.order_id = this.order_id
      const {data: {list}} = await Api.listAll(this.queryParams)
      list.map(item => item.show = false)
      this.orderVehicleList = list
      this.confirmLoading = false
    },

    // 添加车辆
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    // 编辑信息
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    // 删除信息
    handleDelete(item) {
      Api.deleted({ovId: item['ov_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.getData()
          })
    },

    handleSubmit() {

    },
    handleCancel() {
      this.visible = false

    },
  }
}
</script>
<style scoped lang="less">
.info {
  padding: 10px;
  border: 1px #e8e8e8 solid;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 10px;

  .image {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px #e8e8e8 solid;
    background-size: cover;
  }
}

.info-item {
  margin-bottom: 10px;
  display: flex;

  .price {
    color: #a60000;
  }
}
</style>

