<template>
  <div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in files">
        <a v-if="isVideo(item.file_ext)" target="_blank" :href="item.external_url">
          <img class="cover" :src="item.preview_url"/>
        </a>
        <a v-else target="_blank" :href="item.external_url"><img class="cover" :src="item.external_url"/></a>
      </div>
    </div>
  </div>
</template>
<script>
import {debounce, isVideo} from '@/utils/util'
import PropTypes from "ant-design-vue/lib/_util/vue-types";
export default {
  name: 'PreviewFile',
  props: {
    files: PropTypes.array.def([]),
  },
  data() {
    return {
    }
  },
  methods: {
    isVideo,
  }
}
</script>

<style scoped lang='less'>
.list {
  display: flex;

  .list-item {
    width: 70px;
    height: 70px;
    border: 1px #d9d9d9 solid;
    border-radius: 5px;
    color: #d9d9d9;
    text-align: center;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 26px;

    position: relative;

    .cover {
      width: 68px;
      height: 68px;
      border-radius: 5px;
    }

    .del-btn {
      position: absolute;
      top: 0;
      right: 0;
      background: red;
      width: 20px;
      height: 20px;
      line-height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &:last-child {
      margin-right: 0
    }
  }
}

.image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px dashed #e8e8e8;
  color: #dad9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px dashed #40a9ff;
    color: #40a9ff;
  }

  .icon-plus {
    font-size: 32px;
  }
}
</style>
