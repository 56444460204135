<template>
  <a-modal
      title="温馨提示"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div style="width: 100%;display: flex;justify-content: center;align-items: center">
      <div style="text-align: center">
        <a-alert v-if="trial==0 && days>0"
                 :message="'您有' + days + '天的免费试用可以领取！请使用手机扫描下方二维码进行领取'" type="info"/>
        <a-alert v-if="trial==2" message="您的试用已过期，请使用手机扫描下方二维码续费" type="error"/>
        <a-alert v-if="trial.start_time_text && trial.end_time_text"
                 :message="'您当前试用有效期为'+trial.start_time_text+'~'+ trial.end_time_text+'，如需增加时长，请扫描下方二维码续费'"
                 type="success"/>
        <div style="padding: 40px">
          <img style="width: 240px;margin-top: 20px"
               src="@/assets/mpcode.jpeg">
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import * as cardApi from "@/api/store/card";

export default {
  name: '',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      trial: {},
      days: 0
    }
  },
  methods: {
    show() {
      this.getTrial()
      this.visible = true
    },
    async getTrial() {
      const {data: {trial, days}} = await cardApi.isTrial()
      this.trial = trial
      this.days = days
      // this.description = '您已领取免费试用，当前不限制车位数量。\n试用时间：' + trial.start_time_text + ' - ' + trial.end_time_text
    },
    handleOk() {
    },
    handleCancel() {
      this.visible = false
    },
  }
}
</script>

<style scoped lang="less">

</style>
