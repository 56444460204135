<template>
  <a-spin :spinning="loading">
    <div style="display: block">
      <s-table
          ref="table"
          rowKey="vm_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:450}"
      >
        <div slot="vm_type" slot-scope="text, item">
          <a-tag v-if="text==0" color="blue">保养</a-tag>
          <a-tag v-else-if="text==1" color="red">维修</a-tag>
          <a-tag v-else>其他</a-tag>
        </div>
        <div slot="status" slot-scope="text, item">
          <a-tag v-if="item.status==0">待审核</a-tag>
          <a-tag color="green" v-if="item.status==1">审核通过</a-tag>
          <template v-if="item.status==2">
            <a-tag color="red">
              审核驳回
            </a-tag>
            <br>
            <span style="color: red">{{ item.reject_text }}</span>
          </template>
        </div>
        <div slot="project" slot-scope="text, item">
          <div class="descriptionTxt">{{text}}</div>
        </div>
        <div slot="amount" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
          <a-tag color="green" v-if="item.amount_type==0">自行垫付</a-tag>
          <a-tag color="blue" v-if="item.amount_type==1">公司已付</a-tag>
        </div>
        <div slot="kilometers" slot-scope="text, item">
          <div v-if="text">{{ text }}km</div>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <template v-if="item.status==0">
            <a v-action:audit
               @click="handleAuditPass(item,1)">审核通过</a>
            <a v-action:audit
               @click="handleAuditNoPass(item,2)">审核驳回</a>
          </template>
          <a v-action:edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>

    </div>
    <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    <fill-reject-text
        ref="FillRejectText"
        @handleSuccess="onAuditNoPass"
    />
  </a-spin>
</template>
<script>
import * as Api from '@/api/vehicle/maintenanceLog'
import {STable, FillRejectText} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";
import pred from "@/views/vehicle/index/pre/Index.vue";

export default {
  name: "ShowToolList",
  components: {pred, SearchForm, SaveForm, STable, FillRejectText},
  data() {
    return {
      title: '保养维修记录',
      queryParam: {
        vm_type: '',
        status: 0
      },
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '车牌号码',
          dataIndex: 'vehicle.vehicle_name',
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '保养类型',
          dataIndex: 'vm_type',
          scopedSlots: {customRender: 'vm_type'}
        },
        {
          title: '项目名称',
          width: 220,
          dataIndex: 'project',
          scopedSlots: {customRender: 'project'}
        },
        {
          title: '金额',
          dataIndex: 'amount',
          width: 160,
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: '保养时间',
          dataIndex: 'vm_date',
          width: 150,
        },
        {
          title: '仪表公里数',
          dataIndex: 'kilometers',
          width: 150,
          scopedSlots: {customRender: 'kilometers'},
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          width: 150,

        },

        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //
        //   scopedSlots: {customRender: 'source'},
        //   width: 60,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          width: 200,
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      currId: ''
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 保养维修记录'
      this.visible = true
      this.vehicle_id = vehicle_id
    },
    cancel() {
      this.visible = false
    },

    handleAuditPass(item, val) {
      Api.audit({vmId: item['vm_id'], form: {status: val}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleAuditNoPass(item, val) {
      this.currId = item.vm_id
      this.$refs.FillRejectText.open()
    },

    onAuditNoPass(text) {
      Api.audit({vmId: this.currId, form: {status: 2, reject_text: text}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDetails() {

    },

    handleDelete(item) {
      Api.deleted({vmId: item['vm_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">
.descriptionTxt {
  width: 220px;
  word-wrap: break-word;
  padding: 2px !important;
  white-space: pre-wrap;
}
</style>
