<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <template v-if="mode==='add'">
          <a-form-item label="选择车辆（支持多选）" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                mode="multiple"
                @change="rendVehicleTabel"
                show-search
                :filter-option="filterOption"
                v-decorator="['vehicle_ids',{rules: [{required: true, message: '请选择车辆'}]}]"
                placeholder="请选择车辆"
            >
              <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                {{ item.vehicle_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div class="table" v-if="!isEmpty(form.getFieldValue('vehicle_ids'))">
            <div class="tr">
              <div class="td">牵引车车号</div>
              <div class="td">挂车车号</div>
              <div class="td">主班驾驶员</div>
              <div class="td">副班驾驶员</div>
              <div class="td">押运员</div>
              <div class="td">装货趟数</div>
            </div>
            <div class="tr" v-for="item in vehicleTableList">
              <div class="td">{{ item.cl.xsz_hphm }}</div>
              <div class="td">{{ item.gc.xsz_hphm }}</div>
              <div class="td">
                <a-select
                    allowClear
                    show-search
                    :filter-option="filterOption"
                    v-model="item.driver_id"
                >
                  <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="td">
                <a-select
                    allowClear
                    show-search
                    :filter-option="filterOption"
                    v-model="item.driver_id2"
                >
                  <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="td">
                <a-select
                    allowClear
                    show-search
                    :filter-option="filterOption"
                    v-model="item.supercargo_id"
                >
                  <a-select-option v-for="item in supercargo_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="td">
                <a-input-number v-model="item.number" :min="1" :max="999"/>
              </div>
            </div>
          </div>
        </template>
        <template v-if="mode==='edit'">
          <a-form-item v-if="record.status==2" label="完成时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-date-picker
                style="width:100%"
                allowClear
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                v-decorator="['complete_time',{rules: [{required: true, message: '请选择完成时间'}]}]"
            />
          </a-form-item>
          <a-form-item label="当前状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-radio-group
                v-decorator="['status',{initialValue:-1}]"
                @change="getData"
            >
              <a-radio-button :value="4">未收到</a-radio-button>
              <a-radio-button :value="5">已收到</a-radio-button>
              <a-radio-button :value="0">待提交审核</a-radio-button>
              <a-radio-button :value="1">待审核</a-radio-button>
              <a-radio-button :value="2">审核通过/已完成</a-radio-button>
              <a-radio-button :value="3">审核驳回</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="form.getFieldValue('status')==3" label="驳回原因" :labelCol="labelCol"
                       :wrapperCol="wrapperCol">
            <a-textarea
                v-decorator="['reject_text']"
                placeholder="输入驳回原因..."
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
          <a-form-item label="装货磅单" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <multiple-img-url-upload
                :file="record.zhbd"
                v-decorator="['zhbd',{initialValue:[]}]"
            />
          </a-form-item>
          <a-form-item label="卸货磅单" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <multiple-img-url-upload
                :file="record.xhbd"
                v-decorator="['xhbd',{initialValue:[]}]"
            />
          </a-form-item>
          <a-form-item label="送货单" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <multiple-img-url-upload
                :file="record.shd"
                v-decorator="['shd',{initialValue:[]}]"
            />
          </a-form-item>
          <a-form-item label="装货数量" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
                v-decorator="['zhsl']"
                placeholder="请输入装货数量"
                suffix="吨"
            />
          </a-form-item>
          <a-form-item label="卸货数量" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
                v-decorator="['xhsl']"
                placeholder="请输入卸货数量"
                suffix="吨"
            />
          </a-form-item>

          <!--          <template-->
          <!--              v-for="item in record.cost_list"-->
          <!--          >-->
          <!--            <a-form-item-->
          <!--                :label="item.cost_name" :label-col="labelCol" :wrapper-col="wrapperCol">-->
          <!--              <a-input-->
          <!--                  v-model="item.cost_price"-->
          <!--                  :placeholder="'请输入'+item.cost_name"-->
          <!--                  suffix="元"-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--            <a-form-item-->
          <!--                :label="item.cost_name+'金额类型'" :label-col="labelCol" :wrapper-col="wrapperCol">-->
          <!--              <a-radio-group-->
          <!--                  v-model="item.cost_price_type"-->
          <!--              >-->
          <!--                <a-radio :value="0">-->
          <!--                  自行垫付-->
          <!--                </a-radio>-->
          <!--                <a-radio :value="1">-->
          <!--                  公司已付-->
          <!--                </a-radio>-->
          <!--              </a-radio-group>-->
          <!--            </a-form-item>-->
          <!--          </template>-->
          <a-form-item
              label="费用项目" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-button @click="addCost" type="primary">增加一项</a-button>
            <table border class="cost_table">
              <tr>
                <td>已选项目</td>
                <td>金额</td>
                <td>金额类型</td>
                <td>操作</td>
              </tr>
              <tr
                  v-for="(item,index) in cost_list"
              >
                <td>
                  <a-select
                      placeholder="请输入项目"
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.cost_id"
                      @change="setCostName($event,index)"
                  >
                    <a-select-option v-for="item in all_cost_list" :value="item.cost_id">
                      {{ item.cost_name }}
                    </a-select-option>
                  </a-select>
                </td>
                <td>
                  <a-input-number
                      style="width: 100%"
                      v-model="item.cost_price"
                      placeholder="请输入金额"
                  />
                </td>
                <td>
                  <a-radio-group
                      v-model="item.cost_price_type"
                  >
                    <a-radio :value="0">
                      自行垫付
                    </a-radio>
                    <a-radio :value="1">
                      公司已付
                    </a-radio>
                  </a-radio-group>
                </td>
                <td><a style="color: red" @click="delCost(index)">删除</a></td>
              </tr>
            </table>
          </a-form-item>
          <a-form-item label="费用收据" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <multiple-img-url-upload
                :file="record.fysj"
                v-decorator="['fysj']"
            />
          </a-form-item>
          <a-form-item label="特殊情况备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-textarea
                v-decorator="['tsqkbz']"
                placeholder="请输入特殊情况备注"
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
            <multiple-upload
                :file="record.tsqkbz_file_list"
                v-decorator="['tsqkbz_file',{initialValue:[]}]"
            />
          </a-form-item>

          <template v-if="changeRecord.length>0">
            <a-divider orientation="left">
              更改记录
            </a-divider>
            <table class="newtable" style="width: 100%;border-color: #e8e8e8;text-align: center" border>
              <tr style="font-weight: bold">
                <td class="file">更改人</td>
                <td class="file_name">更改内容</td>
                <td class="file_time">更改时间</td>
              </tr>
              <template v-if="changeRecord.length>0">
                <tr v-for="(item,index) in changeRecord">
                  <td class="file">
                    {{ item.store_user.real_name }}
                  </td>
                  <td class="file_name">
                    {{ item.change_text }}
                  </td>
                  <td class="file_time">
                    {{ item.create_time }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <div style="display: flex;justify-content: center;padding: 20px">
                  <a-empty
                      :image="require('@/assets/original.png')"
                      :image-style="{height: '60px'}"
                  >
                  </a-empty>
                </div>
              </template>
            </table>
          </template>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/order/vehicle'
import * as coseApi from '@/api/data/cost'
import _ from "lodash";
import * as vehicleApi from "@/api/vehicle";
import * as personnelApi from "@/api/personnel";
import {isEmpty} from "@/utils/util";
import {MultipleImgUrlUpload, MultipleUpload} from '@/components'
import * as changeRecordApi from "@/api/vehicle/changeRecord";

export default {
  components: {
    MultipleUpload,
    MultipleImgUrlUpload
  },
  props: ['orderId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 3
      },
      // 输入框布局属性
      wrapperCol: {
        span: 21
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      // 车辆列表
      vehicle_list: [],
      // 司机列表
      driver_list: [],
      // 押运员列表
      supercargo_list: [],
      vehicleTableList: [],

      all_cost_list: [],
      cost_list: [],

      changeRecord: []
    }
  },
  methods: {
    isEmpty,
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    addCost() {
      this.cost_list.push({cost_price_type: 0})
    },
    delCost(index) {
      this.cost_list.splice(index, 1)
    },
    setCostName(e, index) {
      if (isEmpty(e)) return false
      const item = this.all_cost_list.find(item => item.cost_id === e)
      this.cost_list[index].cost_name = item.cost_name
    },

    async getAllCost() {
      this.confirmLoading = true
      const {data: {list}} = await coseApi.getSelectList()
      this.all_cost_list = list
      this.confirmLoading = false
    },
    /**
     * 渲染车辆表格
     */
    rendVehicleTabel(values, option) {
      const list = []
      option.map(item => list.push({...item.data.attrs.item, ...{number: 1}}))
      this.vehicleTableList = list
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '添加调度车辆'
      this.visible = true
      this.getList()
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑调度车辆'
      this.visible = true
      this.record = record

      this.setFieldsValue()
      this.getAllCost()
      this.getChangeRecord()
    },


    async getChangeRecord() {
      this.confirmLoading = true
      const {data: {list}} = await changeRecordApi.getAllList({
        record_id: this.record.ov_id,
        record_type: 3,
      })
      this.confirmLoading = false
      this.changeRecord = list
    },

    getList() {
      this.$nextTick(() => {
        // 车辆信息
        vehicleApi.getSelectList().then(({data: {list}}) => {
          this.vehicle_list = list
        })
        // 司机与押运员
        personnelApi.all().then(({data: {driver_list, supercargo_list}}) => {
          this.driver_list = driver_list
          this.supercargo_list = supercargo_list
        })
      })
    },


    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, [
          'complete_time', 'status', 'zhbd', 'xhbd', 'shd', 'zhsl',
          'xhsl', 'fysj', 'tsqkbz', 'tsqkbz_file'
        ])
        setFieldsValue(data)
        this.$nextTick(() => {
          setFieldsValue(_.pick(this.record, ['reject_text']))
        })
        this.cost_list = this.record.cost_list.map(res => {
          return {
            cost_id: res.cost_id,
            cost_name: res.cost_name,
            cost_price: res.cost_price,
            cost_price_type: res.cost_price_type
          }
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      const errItem = this.cost_list.find(item => {
        return !item.cost_id || !item.cost_name || !item.cost_price || item.cost_price_type === undefined
      })
      if (!isEmpty(errItem)) {
        this.$message.error('请完善费用项目！')
        return
      }
      this.confirmLoading = true

      var sendApi = null
      if (this.mode == "add") {
        values.order_id = this.orderId
        values.vehicleList = this.vehicleTableList
        sendApi = Api.add({form: values})
      } else {
        values.cost_list = this.cost_list
        sendApi = Api.edit({ovId: this.record['ov_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.cost_table {
  width: 100%;
  border-color: #e8e8e8;

  td {
    padding: 10px;
  }
}
</style>
