<template>
  <div :class="wrpCls">
    <!--    <div style="margin-right: 10px">-->
    <!--      <a @click="$refs.showMpCode.show()">开通车位卡</a>-->
    <!--    </div>-->

    <!--    <div style="margin-right: 10px">-->
    <!--      <a>已购车位卡</a>-->
    <!--    </div>-->

    <div style="margin-right: 10px">
      <a @click="showPendingModel">待处理事项({{ total}})</a>
    </div>

    <div style="margin-right: 10px">
      <a @click="showWord">操作说明</a>
    </div>


    <div>
      <a-tag color="green" v-if="storeInfo.status==1">正式版</a-tag>
      <a-tag v-else>演示版</a-tag>
    </div>


    <showMpCode ref="showMpCode"/>
    <PendingModel ref="PendingModel"/>
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :store-info="storeInfo" :class="prefixCls"/>
    <!-- <select-lang :class="prefixCls" /> -->
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import showMpCode from '@/components/showMpCode/Index.vue'
import PendingModel from './PendingModel/Index'
// import SelectLang from '@/components/SelectLang'
import {Modal} from 'ant-design-vue'
import store from "@/store";
import {pendingData} from "@/api/store";

const publicConfig = store.getters.publicConfig

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    showMpCode,
    PendingModel
    // SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    const currentUser = this.$store.getters.userInfo
    const storeInfo = this.$store.getters.storeInfo
    return {
      showMenu: true,
      currentUser,
      storeInfo,
      total: 0
    }
  },
  methods: {
    showWord() {
      window.open('https://docs.qq.com/doc/p/e7950bf3bca424fa66ee58abe7cdf5b8df586101?u=21a995cc78dc4b27b72d080496aa1607')
    },
    showPendingModel() {
      this.$refs.PendingModel.open()
    },
    async getPendingData() {
      const {data: {total}} = await pendingData()
      this.total = total
    }
  },
  created() {
    if (!this.storeInfo.status && (this.storeInfo.audit_status == 0 || this.storeInfo.audit_status == 3)) {
      Modal.confirm({
        title: '温馨提示',
        content: '您当前为演示版，如需使用正式版请前往申请',
        onOk: () => {
          this.$router.push({path: '/store/info'})
        },
        onCancel() {
        }
      })
    }

    this.getPendingData()
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted() {
  }
}
</script>
