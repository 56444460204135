<template>
  <a-modal
      :title="title"
      :width="520"
      :visible="visible"
      :centered="true"
      :confirmLoading="confirmLoading"
      :footer="null"
      :maskClosable="false"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-input-search
        allowClear
        enter-button
        v-model="queryParam.search"
        placeholder="请输入手机号码"
        @search="handleSerach"
    />
    <template v-if="list.length>0">
      <br/>
      <br/>
      请在下面列表中您要选择的人员信息：
      <div :class="['item',currId==item.store_user_id?'action':'']"  v-for="item in list" @click="handleSubmit(item)">
        <div><span style="color: red">*</span><b>姓名：</b>{{ item.real_name }}</div>
        <div><span style="color: red">*</span><b>手机号码：</b>{{ item.mobile }}</div>
        <div><span style="color: red">*</span><b>入网时间：</b>{{ item.create_time }}</div>
      </div>
    </template>
  </a-modal>
</template>
<script>
import {isEmpty} from "@/utils/util";
import * as Api from '@/api/store/user'
import {isEmptyElement} from "ant-design-vue/lib/_util/props-util";

export default {
  name: 'ChooseStoreUser',
  data() {
    return {
      // 对话框标题
      title: '选择人员',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      queryParam: {
        search: '',
      },
      list: [],
      currId: ''
    }
  },
  methods: {
    isEmptyElement,
    open() {
      this.visible = true
    },
    cancel() {
      this.visible = false

      // this.queryParam.search = ''
      // this.list = []
    },
    handleCheck(item) {
      this.item = item
    },
    async handleSerach() {
      if (isEmpty(this.queryParam.search)) {
        this.$message.error('请输入手机号码！')
        return
      }
      const {data: {list}} = await Api.search(this.queryParam)
      this.list = list
    },
    handleSubmit(item) {
      this.currId = item.store_user_id
      this.$emit('handleSuccess', item)
      this.cancel()
    }
  }
}
</script>
<style scoped lang="less">
.item {
  cursor: pointer;
  border: 1px dashed #7777;
  padding: 10px;
  margin-top: 10px;
  transition: all 0.3s;

  &:hover {
    border-color: #00a0e9;
    color: #00a0e9;
  }
}
.action{
  border-color: #00a0e9;
  color: #00a0e9;
}


</style>
