<template>
  <div>
    <a-upload
        name="iFile"
        :accept="accept"
        :beforeUpload="beforeUpload"
        :customRequest="onUpload"
        :showUploadList="false"
    >
      <template v-if="(forced && !isEmpty(fileInfo))||(!isEmpty(fileInfo) && sValue)">
        <a>{{
            fileInfo.file_name.length > textLength ? fileInfo.file_name.slice(0, textLength) + '...' : fileInfo.file_name
          }}</a>
      </template>
      <template v-else>
        <a style="color: red">点击选取文件</a>
      </template>
    </a-upload>

    <a
        v-if="(forced && !isEmpty(fileInfo))||(!isEmpty(fileInfo) && sValue)"
        @click="del()" style="color: red;margin-left: 10px">删除</a>
  </div>
</template>
<script>
import {debounce, isEmpty} from '@/utils/util'
import * as UploadApi from '@/api/upload'

export default {
  name: 'UploadFile',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Number | String,
    file: Array | Object,
    avatar: {
      type: Boolean,
      default: false
    },
    ispng: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ''
    },
    forced: {
      type: Boolean,
      default: false
    },
    textLength: {
      type: Number,
      default: 10
    },
  },
  watch: {
    value(val) {
      this.sValue = val
    },
    file(val) {
      if (val.preview_url) {
        this.fileInfo = val
      }
    }
  },
  components: {},
  data() {
    return {
      sValue: '',
      uploadSizeLimit: 20,
      uploadUrl: UploadApi.annex,
      fileInfo: this.file,
      showImg: false,
      locFileInfo: {}
    }
  },
  methods: {
    isEmpty,
    // 事件: 上传文件之前
    beforeUpload(file) {
      const that = this
      return new Promise((resolve, reject) => {
        const showErrorMsg = debounce(this.$message.error, 20)
        return resolve(true)
      })
    },
    // 事件: 自定义上传
    onUpload(info) {
      this.isLoading = true
      const formData = new FormData()
      this.locFileInfo = info.file
      formData.append('iFile', info.file)
      formData.append('groupId', 0)
      // 开始上传
      this.uploadUrl(formData)
          .then((res) => {
            this.fileInfo = res.data.fileInfo
            this.sValue = this.fileInfo.file_id
            this.onChange()
          })
    },
    onChange() {
      this.$emit('change', this.sValue, [this.fileInfo], this.locFileInfo)
    },
    del() {
      this.sValue = ''
      this.fileInfo = {}
      this.locFileInfo = {}
      this.$emit('change', this.sValue, [this.fileInfo], this.locFileInfo)
    }
  }
}
</script>

<style scoped lang='less'>
.image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px dashed #e8e8e8;
  color: #dad9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px dashed #40a9ff;
    color: #40a9ff;
  }

  .icon-plus {
    font-size: 32px;
  }
}
</style>
